<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header :name="$translate('auth.user.profile')" previous="Go back to dashboard"
                        @on-previous="router.push({name:'dashboard'})">
                </Header>
            </div>
        </div>
        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-4 md:col-span-3">
                        <div class="bg-white shadow sm:rounded-lg overflow-hidden">
                            <MobileMenu :menu="navigation" />
                            <CardHeader :name="$translate('company.title')" :description="$translate('company.description')">
                                <CompanyProfileForm class="pt-6" :save="bindSaveMethod"/>
                            </CardHeader>
                            <CardFooter :saving="info.saving" :text="$translate('form.saving')">
                                <CardFooterButton :text="$translate('form.update')" @click="save" />
                            </CardFooter>
                        </div>
                    </div>
                    <Submenu :menu="navigation" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import CompanyProfileForm from "@/pages/profile/CompanyForm.vue";
    import {useNotification} from "@/plugins/notification";
    import {useTranslation} from "@/plugins/i18n";
    import Submenu from "@/layouts/components/Submenu.vue";
    import MobileMenu from "@/layouts/components/MobileMenu.vue";
    import {navigation} from "@/pages/profile/profile-menu";
    import router from "@/router";

    export default defineComponent({
        name: "Company",
        components: {
            CompanyProfileForm,
            Submenu,
            MobileMenu,
        },
        setup() {
            const i18n = useTranslation();
            const notification = useNotification();
            let onSaveForm: Function = (): Promise<any> => Promise.resolve();
            const bindSaveMethod = (save: Function) => onSaveForm = save;
            const info = reactive({saving: false});
            const save = () => {
                if (!info.saving) {
                    info.saving = true;
                    onSaveForm().then(() => {
                        info.saving = false;
                        notification.success(i18n.translate('company.title'), i18n.translate('form.updated'));
                    }).catch(() => {
                        info.saving = false;
                    });
                }
            }
            return {
                bindSaveMethod,
                save,
                info,
                navigation,
                router,
            }
        }
    })
</script>