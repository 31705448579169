
    import {defineComponent, reactive} from 'vue';
    import {BaseForm} from "@/types/form";
    import {
        NumberField,
        SelectField,
        TextField,
        SelectButtonField,
        TextareaField,
        PasswordField,
        CheckboxField, RadioField
    } from "@/types/field";
    import {
        BetweenValidationRule,
        EmailValidationRule, MinLengthValidationRule,
        RequiredValidationRule,
        UrlValidationRule
    } from "@/types/validation";
    import {userStore} from "@/store/user";
    import {getErrorMessage} from "@/plugins/axios";
    import {FieldOption} from "@/types/option";
    import {appStore} from "@/store/app";

    export default defineComponent({
        name: "CompanyProfileForm",
        props: {
            save: {
                type: Function,
                default: (): Promise<any> => Promise.resolve()
            }
        },
        setup(props) {

            //  Country field
            const country = new SelectField('country', 'company.country').addRule(new RequiredValidationRule());
            country.removeOptions();
            appStore.getState().countries.forEach(countryOption => {
                country.addOption(new FieldOption(countryOption.code, countryOption.name).preventTranslate());
            })

            //  Form
            const form = reactive(new BaseForm());
            form.addField(new TextField('name', 'company.name').addRule(new RequiredValidationRule()))
            form.addField(new TextField('address', 'company.address').addRule(new RequiredValidationRule()).setWidth(5))
            form.addField(new TextField('postal_code', 'company.postal_code').addRule(new RequiredValidationRule()).setWidth(3))
            form.addField(new TextField('city', 'company.city').addRule(new RequiredValidationRule()).setWidth(4))
            form.addField(country)
            form.addField(new TextField('email', 'company.email').setAutocomplete('username').addRule(new RequiredValidationRule()).addRule(new EmailValidationRule()).setWidth(6))
            form.addField(new TextField('website', 'company.website').setAutocomplete('url').addRule(new RequiredValidationRule()).addRule(new UrlValidationRule()).setWidth(6));

            //  Values
            const values:{ [key: string]: any } = reactive({
                name: userStore.getState().company.company_name,
                address: userStore.getState().company.address,
                postal_code: userStore.getState().company.postal_code,
                city: userStore.getState().company.city,
                country: userStore.getState().company.country,
                email: userStore.getState().company.email,
                website: userStore.getState().company.website,
            });

            //  Bind the save method
            props.save(() => {
                return new Promise((resolve, reject) => {
                    form.setError('').submit().then(() => {
                        userStore.updateCompanyProfile(values.name, values.address, values.postal_code, values.city, values.country, values.email, values.website)
                            .then(() => resolve())
                            .catch(error => reject(getErrorMessage(error.response.data)));
                    }).catch(() => reject());
                })
            });

            return {
                form,
                values
            }
        }
    })
